import { render, staticRenderFns } from "./RegisterConfirm.vue?vue&type=template&id=97dfcf58&scoped=true&"
import script from "./RegisterConfirm.vue?vue&type=script&lang=js&"
export * from "./RegisterConfirm.vue?vue&type=script&lang=js&"
import style0 from "./RegisterConfirm.vue?vue&type=style&index=0&id=97dfcf58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97dfcf58",
  null
  
)

export default component.exports