<template>
	<Page class="page-register-confirm l--content">
		<Title title="Confirm your email address" />

		<p class="page-register-confirm__description">
			We have sent an email with a confirmation link to your email address.
			In order to complete the sign-up process, please click the confirmation link.
		</p>
		<p class="page-register-confirm__description">
			If you do not receive a confirmation email, please check your spam folder.
			Also, please verify that you entered a valid email address in our sign-up form.
		</p>
		<p class="page-register-confirm__description">
<!--			TODO: contact us link to mail or page -->
			If you need assistance, please contact us.
		</p>
	</Page>
</template>

<script>
	import Page from "../components/layouts/LayoutPage";
	import Title from "../components/common/Title";

	export default {
		name: 'RegisterConfirm',
		components: { Page, Title },
	};
</script>

<style lang="scss" scoped>
	.page-register-confirm {
		&__description {
			text-align: center;
			font-size: 1.6rem;
			margin-bottom: 3rem !important;
			@include breakpoint(overPhone) {
				@include center-block;
				max-width: 50rem;
			}
		}
	}
</style>
